import React from 'react'
import Img from "gatsby-image"
import SEO from '../seo'
import LinkMaintainEmbedded from "../LinkMaintainEmbedded";
import {graphql, StaticQuery} from "gatsby";
import AppStoreButtons from "../app-store-buttons";

const GetTheAppComponent = ({embedded, webViewVersion}) => (
  <StaticQuery
    query={graphql`
    query {
      playStore: file(relativePath: { eq: "google-play.png" }) {
        childImageSharp {
          fixed(width: 192, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      appStore: file(relativePath: { eq: "app-store.png" }) {
        childImageSharp {
          fixed(width: 192, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot1: file(relativePath: { eq: "app-screenshots/v3/slice1.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot2: file(relativePath: { eq: "app-screenshots/v3/slice2.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot3: file(relativePath: { eq: "app-screenshots/v3/slice3.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot4: file(relativePath: { eq: "app-screenshots/v3/slice4.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot5: file(relativePath: { eq: "app-screenshots/v3/slice5.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot6: file(relativePath: { eq: "app-screenshots/v3/slice6.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      screenshot7: file(relativePath: { eq: "app-screenshots/v3/slice7.jpg" }) {
        childImageSharp {
          fixed(height: 500, width: 281) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `}
    render={data => {
      const appStoreImage = data.appStore;
      const playStoreImage = data.playStore;
      return (
        <div>
          <SEO title="Download the Camping Checklist application" description="An Application to help get your camping gear organized" />
          <div style={{marginBottom: 20}}><AppStoreButtons data={{ appStoreImage, playStoreImage  }} /></div>
          <ul>
            <li>Create checklists of items to bring on your next camping trip</li>
            <li>Track when items are packed or need to be purchased</li>
            <li>Start checklists from pre-built templates for common outdoor activities
              <ul>
                <li>Hiking, Biking, Outdoor Cooking, Bedding, and Safety</li>
              </ul></li>
            <li>Group checklists to form a whole trip list</li>
            {/*<li><LinkMaintainEmbedded to={'/articles/'} embedded={embedded} webViewVersion={webViewVersion}>Camping tips based on decades of outdoor experience</LinkMaintainEmbedded></li>*/}
            <li>Camping tips based on decades of outdoor experience</li>
            <li><LinkMaintainEmbedded to={'/recommended/'} embedded={embedded} webViewVersion={webViewVersion}>Outdoor Gear tips and recommendations</LinkMaintainEmbedded></li>
            <li>Future: Share lists with friends and family</li>
          </ul>
          <div className="get_the_app_screenshots">
              <Img fixed={data.screenshot1.childImageSharp.fixed} alt="Get organized for your next camping trip" title="Get organized for your next camping trip" />
              <Img fixed={data.screenshot2.childImageSharp.fixed} alt="Create checklists starting from suggested items or your own custom list" title="Create checklists starting from suggested items or your own custom list" />
              <Img fixed={data.screenshot3.childImageSharp.fixed} alt="Track items that have been packed or need to be bought" title="Track items that have been packed or need to be bought" />
              <Img fixed={data.screenshot4.childImageSharp.fixed} alt="Filter the list to see only what needs to be bought" title="Filter the list to see only what needs to be bought" />
              <Img fixed={data.screenshot5.childImageSharp.fixed} alt="Group multiple checklist for a trip" title="Group multiple checklist for a trip" />
              <Img fixed={data.screenshot6.childImageSharp.fixed} alt="Checklist recommendations" title="Checklist recommendations" />
              <Img fixed={data.screenshot7.childImageSharp.fixed} alt="Gear recommendations" title="Gear recommendations" />
          </div>
          <div style={{marginBottom: 20}}><AppStoreButtons data={{ appStoreImage, playStoreImage  }} /></div>
        </div>
      )
    }}
  />

)

export default GetTheAppComponent

