import React from 'react'
import Layout from '../components/layout'
import GetTheAppComponent from "../components/pages";

const embedded = false;
const GetTheAppPage = () => (
  <Layout embedded={embedded}>
    <GetTheAppComponent embedded={embedded} />
  </Layout>
)

export default GetTheAppPage
